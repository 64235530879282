// 平台公告管理
import request from "@/utils/request";

// 新增公告
export function goAddNotice(data) {
  return request("post", "/apm/notice/add", data);
}

// 查询公告详情
export function getNoticeDetail(data) {
  return request("post", "/apm/notice/findById", data);
}

// 查询公告列表
export function getNoticeList(data) {
  return request("post", "/apm/notice/page", data);
}

// 发布下架公告
export function goPublishOrOffShelfNotice(data) {
  return request("post", "/apm/notice/publishOrOffShelf", data);
}

// 删除公告
export function goRemoveNotice(data) {
  return request("post", "/apm/notice/removeById", data);
}
// 编辑公告
export function goUpdateNotice(data) {
  return request("post", "/apm/notice/update", data);
}
