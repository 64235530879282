<!--平台公告管理-->
<template>
  <div class="bulletin">
    <section class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.noticeStatus"
          class="mr20"
          placeholder="请选择状态"
          @change="reload"
          clearable
        >
          <el-option
            v-for="item in ObjToOpt(bulletinStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="tableParams.kw"
          placeholder="请输入公告名称"
          clearable
          @input="kwChange"
          class="mr20"
        />
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goClick(0)">新增公告</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="公告名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.noticeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.addDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.publishTime || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下架时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.offShelfTime || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发布者" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.noticeStatus != 0"
            >{{ scope.row.userName }} | {{ scope.row.userMobile }}</span
          >
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="发布状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ bulletinStatusObj[scope.row.noticeStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(1, scope.row)"
            >查看</el-button
          >
          <template v-if="scope.row.noticeStatus == 0">
            <el-button
              type="text"
              size="medium"
              @click.stop="goClick(2, scope.row)"
              >发布</el-button
            >
            <el-button
              type="text"
              size="medium"
              @click.stop="goClick(3, scope.row)"
              >编辑</el-button
            >
          </template>
          <el-button
            v-if="scope.row.noticeStatus != 1"
            type="text"
            size="medium"
            @click.stop="goClick(4, scope.row)"
            >删除</el-button
          >
          <el-button
            v-if="scope.row.noticeStatus == 1"
            type="text"
            size="medium"
            @click.stop="goClick(5, scope.row)"
            >下架</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      :visible.sync="editShow"
      :title="editTitle"
      :close-on-click-modal="false"
      width="1000px"
      custom-class="add-dialog"
    >
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 公告名称 -->
        <el-form-item label="公告名称" label-width="120px" prop="noticeName">
          <el-input
            v-model.trim="params.noticeName"
            autocomplete="off"
            maxlength="20"
            placeholder="请输入公告名称"
            :disabled="editTitle == '查看公告'"
          ></el-input>
        </el-form-item>
        <!-- 公告内容 -->
        <el-form-item label="公告内容" label-width="120px" prop="noticeDetail">
          <div class="mobile" v-if="editTitle == '查看公告'">
            <span v-html="params.noticeDetail"></span>
          </div>
          <div style="width: 800px; border: 1px solid #ccc" v-else>
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 600px; overflow-y: hidden"
              v-model="params.noticeDetail"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="editTitle != '查看公告'">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  goAddNotice,
  getNoticeDetail,
  getNoticeList,
  goPublishOrOffShelfNotice,
  goRemoveNotice,
  goUpdateNotice,
} from "@/api/brand/bulletin.js";
import { ObjToOpt } from "@/utils/utils";
import { bulletinStatusObj } from "@/db/objs";
import { rules } from "@/db/rules";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      ObjToOpt,
      rules,
      bulletinStatusObj,
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",
        noticeStatus: "",
      },
      editShow: false,
      editTitle: "",
      params: {
        noticeId: "",
        noticeName: "",
        noticeDetail: "",
      },
      editor: null,
      toolbarConfig: {
        excludeKeys: ["group-video"],
      },
      editorConfig: {
        MENU_CONF: {
          uploadImage: {
            base64LimitSize: 5 * 1024 * 10, // 5kb
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  mounted() {
    this.getNoticeList();
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    // 新增公告
    goAddNotice() {
      let params = {
        noticeName: this.params.noticeName,
        noticeDetail: this.params.noticeDetail,
      };
      goAddNotice(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.editShow = false;
          this.getNoticeList();
        }
      });
    },
    // 发布
    goPublishOrOffShelfNotice(noticeStatus) {
      let params = {
        noticeId: this.params.noticeId,
        noticeStatus: noticeStatus,
      };
      goPublishOrOffShelfNotice(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getNoticeList();
        }
      });
    },
    // 更新公告
    goUpdateNotice() {
      let params = {
        noticeId: this.params.noticeId,
        noticeName: this.params.noticeName,
        noticeDetail: this.params.noticeDetail,
      };
      goUpdateNotice(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.editShow = false;
          this.getNoticeList();
        }
      });
    },
    // 删除公告
    goRemoveNotice() {
      let params = {
        noticeId: this.params.noticeId,
      };
      goRemoveNotice(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getNoticeList();
        }
      });
    },
    // 查询公告详情
    getNoticeDetail() {
      let params = {
        noticeId: this.params.noticeId,
      };
      getNoticeDetail(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.params = {
            noticeName: res.data.noticeName,
            noticeDetail: res.data.noticeDetail,
            noticeId: res.data.noticeId,
          };

          this.editShow = true;
        }
      });
    },
    // 查询公告列表
    getNoticeList() {
      getNoticeList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 【监听】表单提交
    onSubmit(formName) {
      if (String(this.params.noticeDetail) == "<p><br></p>")
        this.params.noticeDetail = "";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.noticeId == "") {
            this.goAddNotice();
          } else {
            this.goUpdateNotice();
          }
        } else {
          return false;
        }
      });
    },
    onCreated(editor) {
      this.editor = Object.seal(editor);
      // 一定要用 Object.seal() ，否则会报错
    },

    // 点击
    goClick(val, row) {
      if (val == 0) {
        this.params = {
          noticeId: "",
          noticeName: "",
          noticeDetail: "",
        };
      } else {
        this.params.noticeId = row.noticeId;
      }
      switch (val) {
        case 0:
          this.editTitle = "新建公告";
          this.editShow = true;
          break;
        case 1:
          this.editTitle = "查看公告";
          this.getNoticeDetail();
          break;
        case 2:
          this.$confirm("请确认是否发布?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.goPublishOrOffShelfNotice(1);
          });
          break;
        case 3:
          this.editTitle = "编辑公告";
          this.getNoticeDetail();
          break;
        case 4:
          this.$confirm("请确认是否删除?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.goRemoveNotice();
          });
          break;
        default:
          this.$confirm("请确认是否下架?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.goPublishOrOffShelfNotice(2);
          });
          break;
      }
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getNoticeList();
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getNoticeList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.bulletin {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
.mobile {
  width: 780px;
  height: 580px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #aaa;
}
</style>